
@media (max-width: 574px){
    #equipe{
    flex-direction:column;
    }
    #boxEquipes{
        flex-direction:column;
    }
    #item1Equipe , #item2Equipe,#item3Equipe{
        width:300px;
        height:350px
    }
    #item1Equipe img , #item2Equipe img ,#item3Equipe img{
        width:200px;
    }
    #titreEqui{
        font-size:25px
    }
    
    #equipe #pIdem{
    font-size:40px
    }
    #p2Idem{
        text-align: center;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      
    }
    #paraP{
        padding-left:0.9rem;
        padding-right:0.9rem;
    }

    }

    @media (min-width: 574px){
    #paraP{
      width:700px 
    }

    }