
.memuVDAF{
    font-weight: 500;
    letter-spacing: 0.05em;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: start;
    padding: 1.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
    border-radius: 1rem;
}
.libelleVDAF{
    display:none
}

@media (max-width: 574px){
    #memuVDAFPC{display:none}
    .memuVDAF{
       display:flex;
       position:absolute;
       flex-direction: column;
       top: 115px;
        left:70px
    }
    .libelleVDAF{
        width:250px;
       flex-direction: column;
        background:#f3f4f6;
        display: flex;
        border-radius: 15px; 
        flex-direction: row;
        align-items: center;
        padding:4px ;
        margin-Top:10px
    }
   }
@media (min-width: 574px){
    .memuVDAF{
       display:flex;
       flex-direction: column;
    }
   }
   
