@media (max-width: 574px){
    #titre{
        font-size: larger;
    }
    #nom,#prenoms,#tel,#email,#whatshapp,#filiere,#diplome{
        width: 100%;
    }
    #divFile{
        width: 100%;
    }
    #back{
        left:10px;
        width:95%;
        marginLeft:5px;
        marginRight:5px;
    }
}