


@media (min-width: 574px){
    #prePC{
       display:none
    }
}
@media (max-width: 574px){
    
    #avant{
        flex-direction:column;
        
    }
    #avantGauche p{
        font-size:20px;
        margin-top:5px;
        width:250px;
        height:100px;
    }
    #avantGauche {
        width:100%;
        justify-content: center
    }
    #avantdroite img {
        width:200px;
        margin-top:10px
    }
    #avantdroite  {
        
    }
   }