@media (max-width: 574px){
    #sectionUl{
        padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    overflow-x: scroll;
    width: 350px;
    }

    #titreForm{
     font-size:25px
    }
}