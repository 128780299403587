
.headerPc{
    display:flex;
    width:'100%';
    align-items: center;
    justify-content:'space-between'
}
    
.headerPortable{
  
}

@media (max-width: 574px){
 .headerPc{
    display:none
 }

 

}

@media (min-width: 574px){
   
   .headerPortable{
      display:none
      
   }
   
  
  }