
.surPage,.sousMenu{
   width:'20%'
}
.qseSaction{
   display:flex
}

@media (max-width: 574px){
   .surPage{
      display:none
   }
  
.qseSaction{
   display:flex; 
   align-items: center;
   justify-content: space-between;
   flex:1 1;
   flex-direction: column;
}

  }